export default [
  {
    icon: "home",
    text: "Inicio",
    path: "home",
    name: "RayyoHome",
    fileName: "HomeView",
  },
  {
    icon: "users",
    text: "Usuarios",
    path: "users",
    name: "UsersView",
    meta: { permission: "RAYYO_USERS_LIST_READ" },
    fileName: "UsersView",
  },
  {
    path: "users/:id",
    name: "UserDetailView",
    meta: { permission: "" },
    fileName: "UserDetailView",
  },
  {
    path: "transactions",
    name: "TransactionsView",
    meta: { permission: "CMS_GET_WAL_TXS" },
    fileName: "TransactionsView",
  },
  {
    icon: "credit-card-moves",
    text: "Movimientos Tarjeta",
    path: "movements",
    name: "MovementsView",
    // meta: { permission: "RAYYO_USERS_LIST_READ" }, 
    fileName: "MovementsView",
  },
  {
    path: "movements/conciliation",
    name: "ConciliationView",
    // meta: { permission: "RAYYO_USERS_LIST_READ" }, 
    fileName: "ConciliationView",
  },
  {
    icon: "filter",
    text: "Embudo",
    path: "funnel",
    name: "FunnelView",
    meta: { permission: "CMS_GET_WAL_TXS" },
    fileName: "FunnelView",
  },
]
