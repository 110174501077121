export default [
  {
    text: "Autocomplete",
    name: "AutocompleteView",
    path: "autocomplete",
  },
  {
    text: "Avatar",
    name: "AvatarView",
    path: "avatar",
  },
  {
    text: "Badge",
    name: "BadgeView",
    path: "badge",
  },
  {
    text: "Box",
    name: "BoxView",
    path: "box",
  },
  {
    text: "Breadcrumbs",
    name: "BreadcrumbsView",
    path: "breadcrumbs",
  },
  {
    text: "Button",
    name: "ButtonView",
    path: "button",
  },
  {
    text: "Caption",
    name: "CaptionView",
    path: "caption",
  },
  {
    text: "Card",
    name: "CardView",
    path: "card",
  },
  {
    text: "Chart",
    name: "ChartView",
    path: "chart",
  },
  {
    text: "Checkbox",
    name: "CheckboxView",
    path: "checkbox",
  },
  {
    text: "Collection",
    name: "CollectionView",
    path: "collection",
  },
  {
    text: "DatePicker",
    name: "DatePickerView",
    path: "date-picker",
  },
  {
    text: "DateRange",
    name: "DateRangeView",
    path: "date-range",
  },
  {
    text: "DataTable",
    name: "DataTableView",
    path: "data-table",
  },
  {
    text: "Divider",
    name: "DividerView",
    path: "divider",
  },
  {
    text: "Downloader",
    name: "DownloaderView",
    path: "downloader",
  },
  {
    text: "Dropdown",
    name: "DropdownView",
    path: "dropdown",
  },
  {
    text: "Expandable",
    name: "ExpandableView",
    path: "expandable",
  },
  {
    text: "FileCard",
    name: "FileCardView",
    path: "file-card",
  },
  {
    text: "FilterFinder",
    name: "FilterFinderView",
    path: "filter-finder",
  },
  {
    text: "Form",
    name: "FormView",
    path: "form",
  },
  {
    text: "FileLoader",
    name: "FileLoaderView",
    path: "file-loader",
  },
  {
    text: "Geocoder",
    name: "GeocoderView",
    path: "geocoder",
  },
  {
    text: "Grid",
    name: "GridView",
    path: "grid",
  },
  {
    text: "Icon",
    name: "IconView",
    path: "icon",
  },
  {
    text: "Image loader",
    name: "ImageLoaderView",
    path: "image-loader",
  },
  {
    text: "Input",
    name: "InputView",
    path: "input",
  },
  {
    text: "Label",
    name: "LabelView",
    path: "label",
  },
  {
    text: "Link",
    name: "LinkView",
    path: "link",
  },
  {
    text: "Menu",
    name: "MenuView",
    path: "menu",
  },
  {
    text: "Map",
    name: "MapView",
    path: "map",
  },
  {
    text: "Modal",
    name: "ModalView",
    path: "modal",
  },
  {
    text: "Paginator",
    name: "PaginatorView",
    path: "paginator",
  },
  {
    text: "Progress bar",
    name: "ProgressBarView",
    path: "progress-bar",
  },
  {
    text: "Radio group",
    name: "RadioView",
    path: "radio-group",
  },
  {
    text: "Schedule",
    name: "ScheduleView",
    path: "schedule",
  },
  {
    text: "Sidebar",
    name: "SidebarView",
    path: "sidebar",
  },
  {
    text: "StatusCircle",
    name: "StatusCircleView",
    path: "status-circle",
  },
  {
    text: "Switch",
    name: "SwitchView",
    path: "switch",
  },
  {
    text: "TextArea",
    name: "TextAreaView",
    path: "textarea",
  },
  {
    text: "Tile",
    name: "TileView",
    path: "tile",
  },
  {
    text: "Toaster",
    name: "ToasterView",
    path: "toaster",
  },
  {
    text: "Tooltip",
    name: "TooltipView",
    path: "tooltip",
  },
  {
    text: "VideoLoader",
    name: "VideoLoaderView",
    path: "video-loader",
  },
]